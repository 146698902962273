.value-carrousel {
    width: 100%;
}

.value-carrousel span {
    color: var(--fourth-color);
    font-size: 2rem;
}

.value-carrousel .title {
    height: 15rem;
    margin: 0;
    position: relative;
    padding: auto !important;
    padding-bottom: 7rem;
    background: rgb(136,67,236);
    background: linear-gradient(90deg, rgba(136,67,236,1) 0%, rgba(30,15,52,1) 100%);
}

.value-carrousel span {
    padding: 2rem;
    font-weight: 400 !important;
}

.value-carrousel .titulo {
    font-size: 2.5rem !important;
    margin: 0;
    padding: 2rem !important;
    color: #fff;
    font-weight: 300;
    text-align: left;
}

@media screen and (max-width: 1024px) {
    .value-carrousel .title {
        height: 20rem;
    }

    .value-carrousel .titulo {
        width: 70% !important;
    }
}