/* Estilos de Carousel de Referencias */

#testimonios .white-space{
    height: 25vw;
    background: var(--third-color);
}


#testimonios {
    width: 100%;
    text-align: right;
    color: var(--first-color);
    background: var(--third-color);
}

.testimonial-title {
    text-align: center;
    padding: 2rem;
    font-size: var(--big-font-size);
    font-weight: 350;
}

#testimonios .swiper-slide {
    display: flex ;
    justify-content: center ;
}

#testimonios .swiper-button-prev,
#testimonios .swiper-button-next {
    color: var(--first-color);
}


/* Forma Parte de Nuestro Equipo */
 
#team-form{
    background: var(--third-color);
    color: var(--first-color);
    text-align: center;
    height: 8vw;
    padding: 5rem 0;
}
#team-form h1{
    font-size: 1.4vw;
    text-transform: uppercase;
}
#team-form a {
    font-size: 1rem;
    color: var(--first-color);
    text-transform: uppercase;
    transition: 0.5s ease-in-out all;
}
#team-form a:hover{
    transition: 0.5s ease-in-out all;
    font-size: 1.1rem;
    text-decoration: underline;
}

@media screen and (max-width: 1024px) {
/****** TESTIMONIOS CAROUSEL ******/
    #testimonios {
        padding: 5rem 0;
        height: auto;
    }

    #team-form h1{
        font-size: 1rem;
    }
    #team-form a {
        font-size: 1.5rem;
    }
}



@media screen and (max-width: 768px) {
    #testimonios {
        height: auto;
    }
    
    #testimonios img {
        height: 5rem;
        width: 5rem;
    }

    #team-form h1{
        font-size: 1rem;
    }
    #team-form a {
        font-size: 1.5rem;
    }

}



@media screen and (max-width: 640px) {
    #testimonios {
        padding-bottom: 15rem;
    }
    #team-form {
        height: auto;
    }
}