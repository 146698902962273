/* Estilos Competencia */

#competencia{
    background-color: white;
    position: relative;
    color: var(--first-color);
    padding-top: 5vw;
    height: 30vw;
    margin-bottom: 5rem;
    z-index: 1;
}
#competencia .top{
    display: block;
    float: left;
    padding-left: 10vw;
    font-size: 1.3vw;
    text-transform: uppercase;
}
#competencia .content{
    position: absolute;
    padding-left: 10vw;
    padding-top: 1vw;
    font-size: 4vw;
    font-weight: 300;
}
#competencia .content p {
      margin-block-start: 1em;
      font-size: 4vw;
      font-weight: 300;
      width: 50%;
}
#competencia .nav-comp{
    position: absolute;
    bottom: 5vw;
    right: 15vw;
    font-size: 1.3vw;
    text-transform: uppercase;
    transition: all 0.5s ease;
}
#competencia .nav-comp:hover {
    transform: translateX(20px);
}

/* Tablet Size */
@media screen and (max-width: 1024px) {
    #competencia {
        height: auto;
        margin-bottom: 0;
    }
    #competencia .top, #competencia .nav-comp {
        font-size: 1.5rem;
    }
    #competencia .content {
        position: relative;
        margin-top: 2rem;
    }
    #competencia .content p{
        font-size: 3rem;
        height: auto;
        width: 50%;
    }
    #competencia .nav-comp{
        bottom: 0;
        right: 10vw;
    }
}


@media screen and (max-width: 768px) {
    
    /****** COMPETENCIA ******/
    #competencia {
        height: 80vh;
        margin-bottom: 0;
    }
    #competencia .top {
        padding-left: 5.5vw;
        margin-top: 2rem;
    }
    #competencia .top, #competencia .nav-comp {
        font-size: 1.1rem;
    }
    #competencia .content {
        line-height: 4rem;
        padding-left: 5.5vw;
        padding-top: 5rem;
    }
    #competencia .content p{
        font-size: 2.5rem;
        width: auto;
    }
    #competencia .nav-comp{
        bottom: 5vw;
        right: 10vw;
    }
}



@media screen and (max-width: 640px) {
    #competencia .content p{
        font-size: 2rem;
        width: auto;
    }
}