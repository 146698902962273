
.mkt-product {
    display: flex;
    width: 30rem;
    margin: 2rem 0;
    /* border: 1px solid red; */
}

.mkt-product img {
    object-fit: contain;
    width: 40%;
}

.mkt-product .content {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 1rem 1.5rem;
}

.mkt-product .content h3 {
    font-size: 1.35rem;
    font-weight: 400;
}

.mkt-product .content .date {
    margin-bottom: 1.5rem;
}

.mkt-product .content .date p {
    margin: 0;
}

.mkt-product .content .date span {
    color: gray;
}

.mkt-product button {
    border: none;
    background: none;
    text-transform: uppercase;
    text-align: left;
    color: var(--first-color);
    transition: all 0.5s ease-in-out;
}

.mkt-product button:hover {
    transform: translateX(10px);
    color: var(--fourth-color);
}

@media screen and (max-width: 1024px) {
    .mkt-product {
        width: auto;
        height: 20rem;
    }
}

@media screen and (max-width: 640px) {
    .mkt-product {
        height: auto;
        flex-direction: column;
        align-items: center;
    }
    .mkt-product img {
        width: 10rem;
    }
    .mkt-product .content {
        width: auto;
    }
}