.marketplace-catalog{ 
    margin: 5rem 7vw;
}

.marketplace-catalog h2 {
    padding: 5rem 2rem 0 3rem;
}
.marketplace-catalog .selectors {
    margin-top: 3rem;
    margin-left: 3rem;
    display: flex;
    justify-content: space-between;
}

.marketplace-catalog .underline { 
    position: relative;
    overflow: hidden;
    width: 20rem;
}

.marketplace-catalog .underline:before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 3px;
    background: var(--fourth-color);
}

.marketplace-catalog .underline:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -100%;
    width: 100%;
    height: 3px;
    background: var(--first-color);
    transition: left .6s ease-in;
}

.marketplace-catalog .underline:hover h3,
.marketplace-catalog .underline:hover:after {
    left: 0;
    color: var(--first-color);
}

.marketplace-catalog .underline h3 {
    font-size: 1.35rem;
}

.marketplace-catalog .products {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    /* margin-top: 3rem; */
}



@media screen and (max-width: 1024px) {

    .marketplace-catalog {
        margin: 5rem 8vw;
    }

    .marketplace-catalog h2 {
        padding: 5rem 2rem 0 1.5rem;
    }
    .marketplace-catalog .selectors {
        margin: 0;
        padding: 0;
    }
    .marketplace-catalog .underline {
        width: 15rem;
    }
}

@media screen and (max-width: 640px) {
    .marketplace-catalog h2 {
        padding: 0.5rem;
    }

    .marketplace-catalog .underline {
        width: auto;
    }
}