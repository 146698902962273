.dropdown-card {
    position: sticky;
    transition: 0.3s ease-in-out;
    height: 15rem;
    background: #fff;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    width: 20rem;
}

.dropdown-card:hover {
    height: 30rem;
    /* overflow: scroll; */
}

.dropdown-card .content {
    /* position: sticky; */
    width: 20rem;
    height: 15rem;
    overflow: scroll;
}


.dropdown-img {
    /* position: absolute; */
    top: -15rem;
    height: 15rem;
    width: 20rem;
    background-size: cover;
    border: none;
}

.dropdown-img h1 {
    color: #fff;
    font-size: 1.5rem;
}

.dropdown-list {
    position: relative;
    color: #fff;
    display: flex;
    flex-direction: column;
    /* width: 20rem; */
    border: 1px solid #fff;
    background: #fff;
}

.dropdown-list button {
    position: relative;
    background: none;
    border: none;
    padding: 1rem;
    font-size: 1rem;
    font-family: 'Manrope', sans-serif;
    transition: all 0.5s ;
}
.dropdown-list button:hover {
    background: var(--first-color);
    color: var(--third-color);
}



@media screen and (max-width: 1024px) {

    .dropdown-card {
        height: 10rem;
        width: 15rem;
        margin: 2rem 0;
    }
    .dropdown-card:hover {
        height: 30rem;
    }

    .dropdown-img {
        height: 10rem;
        width: 15rem;
    }

    .dropdown-img h1 {
        font-size: 1.2rem;
    }
    .dropdown-list {
        height: 15rem;
    }
}


@media screen and (max-width: 640px) {
    .dropdown-img {
        /* margin: 2rem 0; */
        width: 100%;
    }
    .dropdown-img h1 {
        font-size: 1.5rem;
    }
}