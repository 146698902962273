
.adventage {
    width: 25rem;
    display: flex;
    margin: 1rem;
}

.adventage .icon {
    height: 3rem;
    width: 3rem;
    padding: 1.5rem;
    object-fit: contain;
}


.adventage h3 {
    color: var(--third-color);
    font-size: 1.65rem;
    font-weight: 300;
}


@media screen and (max-width: 1024px) {
    .adventage {
        width: auto;
    }
}

@media screen and (max-width: 640px) {
    .adventage {
        flex-direction: column;
    }
    .adventage .icon {
        padding: 0.5rem;
    }
    .adventage h3 {
        margin: 0.5rem 0;
    }
}