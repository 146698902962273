.catalog-bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.catalog-bottom .question{
  width: 35rem;
  text-align: left;
  font-size: 2.5rem;
  text-transform: uppercase;

  color: var(--fourth-color);
}
.catalog-bottom .question span { 
  font-weight: 300;
  font-size: 1.5rem;
  display: flex;
  justify-content: flex-end;
  margin-right: 6rem;
}

.catalog-bottom .links {
    width: 35rem;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    margin-bottom: 3rem;
}

.catalog-bottom .links a {
  /* width: 30rem; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-bottom: 2px solid var(--fourth-color); */
}

.catalog-bottom span {
  color: var(--first-color);
  padding-right: 1rem;
}

.catalog-bottom .arrow a,
.catalog-bottom .arrow .pop-button {
  border: none;
  font-size: var(--h3-font-size);
  text-transform: uppercase;
  padding: 1rem 0 1rem;
  font-weight: 500;
  color: gray;
  background: none;

}


.catalog-bottom .arrow .pop-button {
  margin-top: 1rem;
}

.catalog-bottom .arrow {
  width: 30rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  position: relative;
  overflow: hidden;
  line-height: 46px;
  padding: 0 0 6px;
}

.catalog-bottom .arrow:before {
  content: "";
  position: absolute;
  bottom: 0;
  /* left: -100%; */
  width: 100%;
  height: 3px;
  background: var(--third-color);
}

.catalog-bottom .arrow:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -100%;
  width: 100%;
  height: 3px;
  background: var(--fourth-color);
  transition: left .6s ease-in;
}

.catalog-bottom .arrow:hover a,
.catalog-bottom .arrow:hover button,
.catalog-bottom .arrow:hover:after {
  left: 0;
  color: var(--fourth-color);
}

  

@media screen and (max-width: 1024px) {
  .catalog-bottom .question {
    width: 100%;
    padding: 0 3rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .catalog-bottom .question span {
    font-weight: 300;
    font-size: 1.5rem;
    display: unset;
    margin: 0;
  }

  .catalog-bottom .links{
    width: 100%;
    margin: 3rem;
    align-items: flex-end;
  }
}


@media screen and (max-width: 640px) {
  .catalog-bottom .question {
    width: auto;
  }

  .catalog-bottom .links {
    margin: 2rem;
    width: 100%;
  }
}