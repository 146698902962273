/* Estilos cuarto header */

#catalogo-header{
    background: url("../../../assets/images/Catalogo.jpg") no-repeat;
    background-size: 100% 100%;
    height: 100vh;
    color: white;
}

#catalogo-header .menu li a, 
#catalogo-header .menu li button {
    color: black;
}

#catalogo-header .top{
    padding-top: 5vw;
    padding-left: 10vw;
    color: black;
    font-size: 1.1vw;
    text-transform: uppercase;
}

#catalogo-header #main-text {
    color: black;
    font-size: 4vw;
    text-transform: uppercase;
    font-weight: 300;
    width: 60vw;
    padding-top: 3vw;
    padding-left: 10vw;
}

#catalogo-header #main-text p {
    font-family: "Florence";
}

#catalogo-header #main-text span {
    font-family: "Florence Outline";
    font-size: 5vw;
}


#catalogo-header #bottom-text .left{
    font-size: 1.1vw;
    padding-left: 10vw;
    /* padding-top: 5vw; */
    text-transform: uppercase;
    width: 30rem;
    transition: all 0.5s ease;
}
#catalogo-header #bottom-text .left:hover {
    transition: all 0.5s ease;
    transform: translateX(20px);
}
#catalogo-header #bottom-text .left a{
    color: black;
}




@media screen and (max-width: 1024px) {
    #catalogo-header {
        height: auto;
    }
    #catalogo-header .top {
        margin-top: 30%;
        font-size: 1.5rem;
    }

    #catalogo-header #main-text {
        font-size: 3rem;
        font-weight: 600;
        width: 85vw;
    }

    #catalogo-header #main-text span {
        font-size: 5rem;
    }

    #catalogo-header #bottom-text .left{
        padding-top: 7rem;
        font-size: 1.5rem;
        width: auto;
    }
    
    #catalogo-header .navig .nav-button svg{
        color: #000;
    }
}




@media screen and (max-width: 768px) {
    #catalogo-header .top {
        margin-top: 40%;
        font-size: 1.1rem;
    }
    #catalogo-header #main-text {
        font-size: 2rem;
        font-weight: 600;
        width: auto;
    }
    #catalogo-header #bottom-text .left{
        padding-top: 10rem;
        font-size: 1.1rem;
        width: auto;
    }
}



@media screen and (max-width: 640px) {
    #catalogo-header #main-text {
        font-size: 2rem;
    }
    #catalogo-header #main-text span {
        font-size: 3rem;
    }
}