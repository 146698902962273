/* Estilos de compromiso */

#compromiso { 
    position: relative;
    height: 100vh;
    color: white;
    background-color: var(--first-color);
    z-index: 1;
}
#compromiso .top{
    padding-top: 5vw;
    padding-left: 10vw;
    font-size: 1.3vw;
    text-transform: uppercase;
}
#compromiso .content{
    font-family: "Florence Outline";
    padding-left: 10vw;
    margin-top: 5rem ;
    font-size: 4vw;
    font-weight: 300;
    width: 57vw;
}
#compromiso .bot{
    position: absolute;
    margin-top: 10vw;
    margin-left: 10vw;
    font-size: 1.3vw;
    transition: all 0.5s ease;
    text-transform: uppercase;
}
#compromiso .bot:hover {
    transition: all 0.5s ease;
    transform: translateX(20px);
}
#compromiso .mid-img{
    position: absolute;
    right: 0;
    bottom: -14vw;
}
#compromiso img{
    width: 50vw;
    height: auto;
}


@media screen and (max-width: 1024px) {
    #compromiso {
        height: 80vh;
        padding-bottom: 5rem;
    }

    #compromiso .top, #compromiso .bot {
        font-size: 1.5rem;
     }
     #compromiso .content {
        padding-top: 1rem;
        font-size: 4rem;
    }
    #compromiso .mid-img{
        bottom: -25vw;
    }
    #compromiso img{
        width: 80vw;
        height: auto;
    }
}

@media screen and (max-width: 1024px) and (max-height: 700px){
    #compromiso {
        height: 150vh;
    }
}

@media screen and (max-width: 850px) {
    #compromiso {
        height: 100vh;
    }
}




@media screen and (max-width: 760px) {

    /****** COMPROMISO  ******/
    #compromiso {
        height: 80vh;
    }
    #compromiso .top, #compromiso .bot {
        padding-left: 5.5vw;
        font-size: 1.1rem;
        margin: 0;
     }
    #compromiso .content {
        padding-left: 5.5vw;
        font-size: 2.3rem;
    }
    #compromiso .bot {
        margin-top: 6rem;
    }
}