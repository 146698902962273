/* Estilos de compañia */

#compania {
    height: 100vh;
    background-image: url("../../../assets/images/home/background.jpg");
    background-attachment: fixed;
    background-size: cover;    
    background-position: 50% center;
    background-repeat: no-repeat;
    color: white;
}
#compania .before, #compania .text, #compania .after{
    font-family: "Florence";
    color: #CDFB4F;
}
#compania .top{
    padding-top: 5vw;
    padding-left: 10vw;
    font-size: 1.3vw;
    text-transform: uppercase;
}
#compania .content {
    position: absolute;
    padding-left: 10vw;
    padding-top: 7rem;
    font-size: 4vw;
    font-weight: 300;
    transition: 1.2s all cubic-bezier(0, .28, .5, 1);
    transform: scale(1);
    width: 60%;
}
#compania .content:hover {
    transition: 1.2s all cubic-bezier(0, .28, .5, 1);
    transform: scale(1.1);
}

@media screen and (max-width: 1024px) {
    #compania .top {
        padding-top: 5rem;
        font-size: 1.5rem;
    }
    #compania .content {
        /* padding-top: 15rem; */
        font-size: 4rem;
        line-height: 4rem;
    }
}

@media screen and (max-width: 768px) {

    /****** COMPANIA ******/
    #compania .top, #compania .content {
        padding-left: 5.5vw;
    }
    #compania .top {
        padding-top: 2rem;
        font-size: 1.1rem;
    }
    #compania .content {
        padding-top: 10rem;
        font-size: 4rem;
        line-height: 5rem;
    }
}


@media screen and (max-width: 640px) {
    #compania .content {
        width: auto;
        font-size: 2rem;
        line-height: 4rem;
    }
}