/* Estilos del Footer */

.footer{
    background: var(--fourth-color);
    color: white;
    /* height: 100vh; */
    z-index: 0;
    padding: 5rem;
}

.footer .logo {
    height: 5rem;
}

.footer h3 {
    border-bottom: 2px solid gray;
    padding-bottom: 1rem;
    width: 15rem;
}

.footer .data {
    display: flex;
    justify-content: space-around;
}
.footer .data .left{
    width: 40%;
    display: flex;
    flex-direction: column;
}
.footer .data .right {
    width: 60%;
}

/* Left Side */
.footer .left .footer-menu {
    padding: 0;
    margin-bottom: 3rem;
}
.footer .left .footer-menu li {
    text-decoration: none;
    list-style: none;
    padding: 0;
    margin: 1.25rem 0;
    transition: all 0.5s ease;
}
.footer .left .footer-menu li a {
    color: #fff;
}

.footer .left .footer-menu li:hover {
    transform: translateX(10px);
}
.footer .left .footer-menu li:hover a,
.footer .right .social-media a:hover svg {
    color: var(--second-color) !important;
}

.footer .copy{
    font-size: 1rem;
    color: gray;
}



/* Right Side */
.footer .right{
    display: flex;
    justify-content: flex-start;
}

.footer .contact,
.footer .schedule {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.right .contact button, 
.right .contact a p {
    border: none;
    background: none;
    color: #fff;
    font-size: 1rem;
    transition: all 0.5s ease;
    text-align: left;
}

.right .contact button:hover , 
.right .contact a:hover p {
    transform: translateX(10px);
    color: var(--second-color);
}

.footer .right .contact .footer-field:nth-child(5) p {
    display: none;
}

.footer .right .social-media a {
    margin-right: 1rem;
}

.footer .right .social-media a svg{
    transition: all 0.5s ease;
}
.footer .right .social-media a:hover svg{
    transform: translateY(-10px);
}


@media screen and (max-width: 1024px) {
    .footer{ 
        height: auto;
    }
    
}

@media screen and (max-width: 768px) {
    .footer {
        padding: 4rem 7vw;
    }
    .footer .data {
        flex-direction: column;
    }
    .footer .data .left,
    .footer .data .right {
        width: auto;
    }

}

@media screen and (max-width: 640px) {

    .footer {
        padding: 3rem;
    }

    .footer h3 {
        width: auto;
    }

    .footer .data .right {
        flex-direction: column;
    }
}