.catalog-categories {
    margin: 5rem 0;
    margin-bottom: 8rem;
}

.catalog-categories .category-carousel:nth-child(4) .main-title {
    color: #000;
}
.catalog-categories .categories {
    position: relative;
    color: #fff;
    background: linear-gradient(
        to top,
        black 0%,
        black 50%,
        white 50%,
        white 100%
    );
    display: flex;
    align-items: center;
    justify-content: space-around;
}

/* .catalog-categories .contact {
    margin: 2rem;
    margin-right: 20rem;
    color: #fff;
    background: #753399;
    border: none;
    font-size: 1.5rem;
    font-weight: 500;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
} */


@media screen and (max-width: 640px) {
    .catalog-categories .categories {
        flex-direction: column;
    }
}