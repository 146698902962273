
.product-header {
    color: white;
    height: 90vh;
}

.product-header .navig {
    position: absolute;
    width: 100%;
    z-index: 2;
}

.product-header .video-container {
    height: 90vh;
    width: 100%;
    object-fit: cover;
}

/* .product-header video {
    height: 10rem;
    width: 20rem;
} */

/* .product-header video source {
    height: 10rem;
    width: 20rem;
} */


@media screen and (max-width: 1024px) {
    .product-header,
    .product-header .video-container {
        height: 40vh;
    }
}


@media screen and (max-width: 640px) {
    .product-header {
        height: 25vh;
    }
}