.marketplace-body .body{
    background: rgb(205,251,79);
    background: linear-gradient(0deg, rgba(205,251,79,1) 30%, #6E7A28 100%);
}

.marketplace-body .body {
    padding: 5rem 0;
    display: flex;
}

.marketplace-body .body .images, 
.marketplace-body .body .resume {
    width: 50%;
    margin-left: 4rem;
}

.marketplace-body .images img {
    height: 15rem;
    margin: 2rem 1rem;
    transition: transform 0.3s;
    overflow: hidden;
}

.marketplace-body .images img:hover {
    transform: scale(1.25);
}

.marketplace-body .body .resume .title {
    font-size: 3.5rem;
    font-weight: 300;
}

.marketplace-body .body .resume p {
    width: 30rem;
}

.marketplace-body .benefits {
    margin: 0 7vw;
}

.marketplace-body .benefits h2 {
    padding: 5rem 2rem 0 3rem;
}
.marketplace-body .benefits .content {
    display: flex;
    justify-content: space-evenly;
}

.marketplace-body .benefits button {
    /* display: block; */
    position: absolute;
    right: 4rem;
    font-size: 1.25rem;
    text-align: right;
    background: none;
    border: none;
    color: var(--firs-color);
    text-transform: uppercase;
    transition: all 0.5s ease;
    margin: 0 2rem;
}

.marketplace-body .benefits button:hover {
    color: var(--fourth-color);
    transform: translateX(15px);
}


.benefit {
    background: var(--fourth-color);
    width: 20rem;
    height: 20rem;
    margin: 2rem;
    padding: 2rem;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.benefit .icon {
    height: 3rem;
    margin: 2rem 0 0;
}


.benefit .benefit-title {
    font-size: 1.5rem;
    font-weight: 300;
    color: var(--second-color);
}
.benefit .benefit-title,
.benefit p {
    text-align: left;
}


@media screen and (max-width: 1024px) {

    .marketplace-body .body{
        background: rgb(205,251,79);
        background: linear-gradient(0deg, rgba(205,251,79,1) 30%, #B4C641 100%);
    }

    .marketplace-body .benefits h2 {
        padding: 5rem 2rem 0 1.5rem;
    }

    .benefit {
        margin: 1rem;
        padding: 1.5rem;
        margin-bottom: 2rem;
    }
}

@media screen and (max-width: 768px) {
    .marketplace-body .body {
        flex-direction: column;
    }
    .marketplace-body .body .images,
    .marketplace-body .body .resume {
        width: 80%;
    }
    .marketplace-body .benefits .content {
        flex-wrap: wrap;
        justify-content: center;
    }
    .marketplace-body .benefits button {
        left: 4rem;
        right: unset;
    }

    .marketplace-body .body .resume .title {
        font-size: 3rem;
    }

    .marketplace-body .body .resume p {
        width: auto;
    }
}


@media screen and (max-width: 640px) {
    .marketplace-body .body {
        padding: 0;
    }
    .marketplace-body .body .images,
    .marketplace-body .body .resume {
        width: auto;
        margin: 0;
    }

    .marketplace-body .body .images {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .marketplace-body .images img {
        margin: 0.5rem 1rem;
    }

    .marketplace-body .body .resume .title {
        font-size: 2rem;
    }

    .marketplace-body .body .resume {
        width: auto;
        padding: 3rem;
    }

    .marketplace-body .benefits button {
        left: 0.5rem;
    }
}