.product-bottom {
    background: var(--fourth-color);
    text-align: center;
    padding-bottom: 5rem;
}



.product-bottom h3 {
    margin: 0;
    font-size: 1.5rem;
    margin-left: 10rem;
    padding-top: 5rem;
    color: #fff;    
    text-align: left;
}
.product-bottom h3 span {
    font-weight: 300;
}

.product-bottom .content { 
    border: 1px solid var(--third-color);
    margin: 3rem 10rem;
    display: flex;
}

.product-bottom .graphics-img {
    width: 70%;
}

.product-bottom .text {
    width: 30%;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem;
}

.product-bottom .text p {
    text-align: left;
}

.product-bottom .text button {
    background: none;
    border: none;
    color: var(--first-color);
    font-size: 1rem;
    padding: 0;
}



@media screen and (max-width: 1024px) {
    .product-bottom .content {
        flex-direction: column;
    }
    .product-bottom .graphics-img,
    .product-bottom .text {
        width: auto;
    }
}

@media screen and (max-width: 640px) {
    .product-bottom .text {
        padding: 3rem;
    }
    .product-bottom .text h3,
    .product-bottom .text p,
    .product-bottom .text button {
        width: auto;
    }
    .product-bottom .text h3 {
        text-align: center;
    }
    .product-bottom .text p
    .product-bottom .text button  {
        text-align: left;
    }

    .product-bottom .text button {
        margin-top: 1rem;
        font-size: 0.95rem;
    }

}