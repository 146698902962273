

/*********** TITULO ***********/
.product-view .title {
    margin: 3rem 5rem;
    margin-top: -15rem;
    padding: 2rem;
    position: absolute;
    background: rgb(232,232,232);
    background: linear-gradient(0deg, rgba(232,232,232,1) 0%, rgba(255,255,255,1) 100%);
}

.product-view .title p,
.product-view .title h2 {
    width: 50%;
}
.product-view .title p {
    text-transform: uppercase;
}
.product-view .title span {
    font-weight: 700;
}

.product-view .title h2 {
    font-size: 3rem;
    font-family: 'Manrope';
    font-weight: 400;
    padding: 3rem 5rem 0;
}
.product-view .title button {
    border: none;
    background: none;
    font-size: 1rem;
    color: var(--first-color);
    padding-left: 5rem;
}



/*********** FACIL ***********/
.product-view .easy {
    padding-top: 15rem;
    background: rgb(232,232,232);
    background-image: linear-gradient(to right bottom, #9147FB,#e8e8e8, #e8e8e8, #e8e8e8, #e8e8e8, #e8e8e8,  #cdfb4f);
    /* background: linear-gradient(90deg, rgba(232,232,232,1) 0%, rgba(232,232,232,1) 90%, rgba(205,251,79,1) 100%); */
}

.product-view .easy img{
    height: 27rem;
    width: 60%;
    object-fit: cover;
}

.product-view .easy .text{
    padding: 3rem 3rem 3rem 13rem;
}

.product-view .easy .text p {
    width: 40%;
}


/*********** COMO FUNCIONA ***********/
.product-view .how-works {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 3rem 5rem;
}

.product-view .how-works div {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 70%;
}

.product-view .how-works p {
    width: 40%;
    padding: 0 1.5rem;
    text-align: justify;
}

.product-view .how-works img{ 
    margin-top: 3rem;
    width: 100%;
}

/* .product-view .video {
    width: 100%;
    height: 75vh;
    margin: 3rem 0;
} */



/*********** VENTAJAS ***********/
.adventages {
    padding: 5rem;
    color: #fff;
    background: var(--fourth-color);
}

.adventages h2 {
    text-align: center;
    margin-left: -6rem;
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 600;
}

.adventages .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}




@media screen and (min-width: 1920px) {
    .product-view .title {
        margin-top: -23rem;
        width: 80%;
    }
}

@media screen and (max-width: 1024px) {
    .product-view .title {
        margin-top: -18rem;
    }
    .product-view .how-works div {
        width: auto;
    }
}



@media screen and (max-width: 768px) {
    .product-view .title {
        margin: 3rem;
        margin-top: -15rem;
    }
    .product-view .title h2 {
        font-size: 2.75rem;
        padding: 3rem 3rem 0;
    }
}



@media screen and (max-width: 640px) {
    .product-view .title {
        margin: 0;
        padding: 2rem;
    }
    .product-view .title h2 {
        padding: 0;
        font-size: 2rem;
    }
    .product-view .title button {
        padding: 0;
    }

    /* Easy */
    .product-view .easy .text,
    .product-view .how-works,
    .adventages {
        padding: 3rem;
    }
    .product-view .easy .text p {
        width: auto;
    }

    /* How it Works */
    .product-view .how-works div {
        flex-direction: column;
    }
    .product-view .how-works p {
        width: auto;
    }

    /* Adventages */
    .adventages h2 {
        margin: 0;
        margin-bottom: 2rem;
    }
    .adventages .container {
        flex-direction: column;
    }

}