.category-carousel {
    /* margin: 3rem 0; */
    /* background: #1C1C1E; */
    color: #fff;
    padding: 2rem 2rem 5rem;
    /* border-radius: 1rem; */
}

.category-carousel .main-title {
    font-size: 2rem;
    /* padding: 1rem; */
}


@media screen and (max-width: 640px) {

    .category-carousel .swiper-slide {
        display: flex;
        justify-content: center;
    }
}