.footer-field {
    display: flex;
    flex-direction: column;
}

.footer-field h4 {
    padding: 0;
    color: gray;
    margin: 0;
    font-weight: 300;
}
.footer-field .description {
    padding: 0;
    margin: 0 0 1.5rem 0;
    width: 60%;
}