.scroll-top {
    position: fixed !important;
    width: 50px;
    height: 50px;
    background: var(--first-color);
    color: #fff;
    bottom: 2rem;
    right: 2rem;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    z-index: 10;
    scroll-behavior: smooth;
}

.scroll-top:hover {
    background: var(--fourth-color);
    transition: all 0.5s ease-in-out;
    cursor: pointer;
}