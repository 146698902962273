
.cat-card {
    width: 17rem;
    height: 24rem;
    margin-right: 3rem;
    background: var(--fourth-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.cat-card .top-number {
    align-self: flex-start;
    margin-left: 2rem;
    margin-bottom: 3rem;

}

.cat-card .swiper-main {
    width: 90%;
    margin: 0 5rem;
    background: #1C1C1E;
}
.cat-card .swiper {
    height: 25rem;
    display: flex;
    align-items: center;
}
.cat-card .swiper-wrapper {
    height: auto;
}

.cat-card img {
    height: 9rem;
    width: auto;
    object-fit: cover;
    border-radius: 10px;
}

.cat-card .card-title, .cat-card .card-description {
    width: 85%;
    margin: 0.5rem 0;
}

.cat-card .card-title {
    color: #fff;
    font-size: 1rem;
    text-transform: uppercase;
    text-align: left;
    margin-top: 1.5rem;
}

.cat-card .card-description {
    margin-top: -0.5rem;
    color: #fff;
    text-align: left;
}

.cat-card .watch-more {
    color: var(--third-color);
    border: none;
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
    border-radius: 0.5rem;
    align-self: flex-start;
    transition: all 0.5s ease-in-out;
    text-transform: uppercase;
    margin-bottom: 0.75rem;
}

.cat-card:hover .watch-more {
    /* cursor: pointer; */
    transform: translateX(10px);
    color: var(--second-color);
}


@media screen and (max-width: 640px) {
    .cat-card {
        margin: 0;
    }
}