.market-product {
    background: var(--fourth-color);
    color: #fff;
}

.market-product .container {
    padding: 5rem 10vw;
    display: flex;
}

/* SIDE MENU */
.market-product .side-menu {
    display: flex;
    flex-direction: column;
    /* width: 20rem; */
    margin-right: 7rem;
}

.market-product .side-menu .other {
    width: 17rem;
    font-weight: 400;
}

.market-product .side-menu input {
    border: none;
    background: rgb(17, 17, 17);
    padding: 0.5rem;
    font-size: 1rem;
    margin-bottom: 1.5rem;
}
.market-product .side-menu p {
    margin: 0.15rem 0;
    color: gray;
}

.market-product .side-menu .support {
    margin-top: 10rem;
    background: rgb(17, 17, 17);
    padding: 1.5rem 1rem;
}

.market-product .side-menu .support h3 {
    margin: 0;
    font-size: 1rem;
    font-weight: 300;
    color: var(--second-color);
    margin-bottom: 1rem;
}
.market-product .side-menu .support span {
    color: var(--second-color);
}

/* ARTICLE */
.market-product .article {
    display: flex;
    flex-direction: column;
    background: #000;
    padding: 2rem 3rem;
    padding-right: 7rem;
}
.market-product .article img {
    width: 40rem;
}

.market-product .article h3 {
    margin: 0;
    margin-bottom: 5rem;
    font-weight: 300;
    color: var(--second-color);
    /* font-size: 1rem; */
}

.market-product .article h1 {
    font-size: 3rem;
    font-weight: 400;
    margin: 0;
}

.market-product .article p {
    width: 50%;
    font-size: 0.9rem;
}

.market-product .article span {
    color: var(--second-color);
    display: block;
    text-align: right;
    margin: 2rem 0 0.5rem 0;
}

@media screen and (max-width: 1024px) {
    .market-product .container {
        margin-top: 5rem;
        padding: 5rem 7vw;
        display: flex;
    }

    .market-product .side-menu {
        margin-right: 3rem;
        width: 30%;
    }
    .market-product .article {
        padding-right: 3rem;
    }
    .market-product .article img {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .market-product .container {
        flex-direction: column;
    }
    .market-product .side-menu {
        width: 100%;
        flex-direction: row;
        align-items: center;
        margin-bottom: 3rem;
    }
    .market-product .side-menu .support,
    .market-product .side-menu .categories {
        width: 50%;
    }
    .market-product .side-menu .support {
        margin-top: 0;
        height: 10rem;
    }
}

@media screen and (max-width: 640px) {
    .market-product .side-menu {
        flex-direction: column;
    }
    .market-product .side-menu .support,
    .market-product .side-menu .categories {
        width: auto;
    }
    .market-product .side-menu .categories {
        margin-bottom: 3rem;
    }

    .market-product .article {
        padding: 2rem;
    }
    .market-product .article h1 {
        font-size: 2rem;
    }
    .market-product .article p {
        width: auto;
    }
    
}