.contact-bottom {
    padding: 5rem;
    background: #000;
    color: #fff;
    display: flex;
}

.contact-bottom .text,
.contact-bottom .video {
    width: 50%;
}

.contact-bottom .video video {
    width: 100%;
    /* position: fixed;
    z-index: 10; */
}

.contact-bottom .text h3 {
    font-size: 4rem;
    font-weight: 400;
    margin-bottom: 1rem;
}

.contact-bottom .text button {
    font-size: 1rem;
    background: none;
    border: none;
    color: var(--second-color);
    margin-bottom: 5rem;
    text-transform: uppercase;
}

.contact-bottom .text button:hover {
    color: var(--first-color);
    transform: translateX(15px);
}


@media screen and (max-width: 1024px) {
    .contact-bottom {
        padding: 3.5rem;
    }
    
    .contact-bottom .text h3 {
        font-size: 3rem;
    }
}


@media screen and (max-width: 640px) {
    .contact-bottom {
        flex-direction: column;
        margin-bottom: 3rem;
    }
    .contact-bottom .text,
    .contact-bottom .video {
        width: 100%;
    }
}