.contact-header {
    height: auto;
    background: url("../../../assets/images/contacto-bg.jpg") center no-repeat fixed;
    background-size: cover;
    color: white;
}

.contact-header .top{
    padding-top: 7vw;
    padding-left: 10vw;
    font-size: 1rem;
    text-transform: uppercase;
}

.contact-header .title {
    font-family: "Florence";
    padding-top: 5vw;
    padding-left: 10vw;
    width: 60vw;
    font-size: 4.3vw;
    font-weight: 300;
    line-height: 1rem;
    text-transform: uppercase;
    color: #FFF;
}

.contact-header .subtitle {
    font-family: 'Florence Outline', sans-serif;
    font-size: 6.5vw;
}

.contact-header .bottom-text {
    display: flex;
    padding-bottom: 2rem;
    /* margin-top: 2rem; */
}

.contact-header .bottom-text .buttons {
    width: 30%;
    padding: 1rem 5rem;
    padding-left: 10vw;
}

.contact-header .bottom-text .contact-button,
.contact-header .bottom-text .meet-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    /* border-bottom: 1px solid #fff; */
    width: auto;
    transition: left .8s ease-in;
}


.contact-header .bottom-text .contact-button p,
.contact-header .bottom-text .contact-button a,
.contact-header .bottom-text .meet-button button {
    font-family: 'Manrope', sans-serif;
    width: auto;
    padding: 1.5rem 1rem;
    color: #FFF;
    background: none;
    border: none;
    font-size: 1rem;
}

.contact-header .arrow {
    position: relative;
    overflow: hidden;
}

.contact-header .arrow:before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: var(--third-color);
}

.contact-header .arrow:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: var(--first-color);
    transition: left .6s ease-in;
}


.contact-header .arrow:hover a,
.contact-header .arrow:hover button,
.contact-header .arrow:hover:after {
    left: 0;
    color: var(--second-color);
}

.contact-header .bottom-text .contact-button a{
    padding: 1.5rem 1rem;
}

.contact-header .bottom-text span {
    color: var(--second-color);
}




@media screen and (max-width: 1024px) {
    .contact-header .top {
        padding-top: 15rem;
        padding-left: 7vw;
    }

    .contact-header .title,
    .contact-header .bottom-text .buttons {
        padding-left: 7vw;
    }

    .contact-header .bottom-text p, 
    .contact-header .bottom-text .buttons {
        padding: 1rem 3.5rem;
    }

}


@media screen and (max-width: 640px) {
    
    .contact-header .title {
        width: auto;
        font-size: 3rem;
        line-height: normal;
    }
    .contact-header .subtitle {
        font-size: 3.5rem;
        line-height: 0;
        margin: 0;
        padding: 0;
    }
    .contact-header .bottom-text {
        flex-direction: column;
        margin-top: 2rem;
    }

    .contact-header .bottom-text p, 
    .contact-header .bottom-text .buttons {
        width: auto;
        padding: 1rem 8vw;
    }
}