.cursor {
    position: fixed;
    width: 20px;
    height: 20px;
    margin-left: -20px;
    margin-top: -19px;
    border-radius: 50%;
    /* border: 2px solid var(--second-color); */
    background: var(--first-color);
    transition: transform 0.2s ease-in-out;
    transform-origin: center center;
    pointer-events: none;
    z-index: 1000;
}

.cursor.grow {
    transform: scale(2);
    background: var(--second-color);
    mix-blend-mode: difference;
    border: none;
}
