/* Estilos del tercer Header */

.marketplace-header{
    height: 100vh;
    background: url("../../../assets/images/marketplace_background.jpg") no-repeat;
    /* background: rgb(205,251,79, 0.9); */
    /* background: linear-gradient(0deg, rgba(205,251,79,1) 39%, rgba(0,0,0,1) 100%); */
    /* mix-blend-mode: difference; */
    background-size: cover;
    background-position: top;
    color: white;
    /* z-index: -2; */
}

.marketplace-header .top{
    color: #fff;
    padding-top: 7vw;
    padding-left: 10vw;
    font-size: 1rem;
    text-transform: uppercase;
}

.marketplace-header .main-text{
    /* padding-top: 3vw; */
    padding-left: 10vw;
    width: 60vw;
    font-size: 4.3vw;
    font-weight: 300;
    font-family: "Florence", sans-serif;
    text-transform: uppercase;
    color: #fff;
}
.marketplace-header .main-text span{
    font-family: "Florence Outline", sans-serif;
    font-size: 5rem;
}
.marketplace-header .bottom-text .left{
    font-size: 1rem;
    padding-left: 10vw;
    text-transform: uppercase;
    width: 30rem;
    transition: all 0.5s ease;
}
.marketplace-header .bottom-text .left:hover{
    transition: all 0.5s ease;
    transform: translateX(20px);
}
.marketplace-header .bottom-text .left a {
    color: #fff;
}



@media screen and (max-width: 1024px) {
    .marketplace-header .top {
        margin-top: 30%;
        font-size: 1.5rem;
    }
    .marketplace-header .main-text {
        font-size: 4rem;
        font-weight: 600;
    }
    .marketplace-header .bottom-text .left{
        padding-top: 15rem;
        font-size: 1.5rem;
        width: auto;
    }
}

@media screen and (max-width: 768px) {
    .marketplace-header .top {
        font-size: 1.1rem;
    }
    .marketplace-header .main-text {
        font-size: 3rem;
        font-weight: 600;
    }
    .marketplace-header .bottom-text .left{
        padding-top: 5rem;
        font-size: 1.1rem;
        width: auto;
    }
}



@media screen and (max-width: 640px) {
    .marketplace-header .main-text {
        font-size: 2rem;
        font-weight: 600;
        width: auto;
        padding-left: 7vw;
    }
}