.navig{
    height: 50px;
    padding-top: 5vw;
}
.navig .logo{
    width: 10vw;
    height: auto;
}

.navig .nav-button {
    visibility: hidden;
}

.navig .icon {
    position: absolute;
    top: 3vw;
    display: block;
    float: left;
    padding-left: 10vw;
    font-size: 1.3vw;
    color: white;
}
.navig .menu {
    position: relative;
    float: right;
    width: 50%;
    margin-right: 0px;
    font-size: var(--normal-font-size);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.navig .menu li{
    display: flex;
    align-items: flex-start;
    list-style: none;
    height: 46px;
    margin-left: 15px;
    margin-right: 15px;
}

.navig .menu li a, 
.navig .menu li button {
    color: #fff;
    text-decoration: none;
    border: none;
    background: none;
    font-size: 1rem;
    transition: all 0.3s ease-in-out;
}

.navig .menu li button {
    padding: 0.2rem 0;
}

.navig .menu li a:hover, 
.navig .menu li button:hover {
    color: var(--second-color);
    transform: translateY(3px);
    text-shadow: 2px 2px 3px var(--first-color);
}




/* Tablet Size */
@media screen and (max-width: 1024px) {
    .navig .icon {
        padding-left: 7vw;
    }
    
    .navig .logo {
        width: 10rem;
        height: auto;
        margin-top: 2rem;
    }

    .navig .nav-button {
        visibility: visible;
        position: absolute;
        top: 4rem;
        right: 3rem;
        z-index: 11;
        border: none;
        background: none;
    }

    .navig {
        /* height: 100vh;   */
        padding-top: 0 !important;
    }

    .navig .menu {
        cursor: default;
        flex-direction: column;
        align-items: center;
        visibility: hidden;
        overflow: hidden;
        width: 100%;
        height: 100%;
        left: 100%;
        position: fixed;
        margin: 0;
        padding: 0;
        opacity: 1;
        transition: all 0.5s ease-in-out;
        z-index: 1;
    }

    .navig .menu.active {
        visibility: visible;
        background: var(--first-color);
        left: 0;
        opacity: 1;
        z-index: 10;
    }

    .navig .menu li {
        margin-top: 3rem;
    }
    .navig .menu li a, 
    .navig .menu li button {
        font-size: 2rem;
    }
    .navig .menu li a:hover, 
    .navig .menu li button:hover {
        cursor: pointer;
    }
}

@media screen and (max-width: 760px) {
    
    /****** NAVBAR ******/
    .navig .icon {
        margin-top: -1rem;
    }
    .navig .logo {
        margin-top: 3rem;
    }
    .navig .nav-button {
        top: 3rem;
        right: 1rem;
    }
    .navig .menu li {
        margin: 2rem 0;
    }
}