/* Conócenos estilos */

/* Conocenmos Text */
#con-text{
    background-color: var(--fourth-color);
    color: white;
    text-align: left;
    height: 45vw;
}
#con-text p {
    width: 33vw;
    padding-top: 2vw;
    position: absolute;
    left: 33vw;
    font-size: var(--h3-font-size);
}
#con-text img {
    width: 85vw;
    height: auto;
    padding-top: 25vw;
}


/* White Text */
#conocenos #white-text{
    color: var(--first-color);
    background-color: white;
    height: 60vw;
}
#conocenos #white-text p{
    text-align: left;
    padding-top: 37vw;
    width: 50%;
    font-size: var(--h3-font-size);
    padding-left: 33vw;
    margin-bottom: 5vw;
}
#conocenos #white-text a{
    color:var(--first-color);
    padding-left: 33vw;
    font-size: 1.3vw;
    text-transform: uppercase;
    transition: all 0.5s ease;
}
#conocenos #white-text a:hover{
    transition: all 0.5s ease;
    transform: translateX(20px);
}


/* Purple Text */
#con-purp {
    background-color: var(--first-color);
    color: white;
    height: 100vh;
    position: relative;
}
#con-purp .top {
    font-size: 1.1vw;
    padding-left: 15vw;
    position: absolute;
    text-transform: uppercase;
    top: 5vw;
}
#con-purp p {
    font-size: 3rem;
    width:60vw;
    padding-left: 15vw;
    padding-top: 15vw;
}
#con-purp a {
    position: absolute;
    color: white;
    padding-left: 15vw;
    padding-top: 7vw;
    font-size: 1.3vw;
    text-transform: uppercase;
    transition: all 0.5s ease !important;
}
#con-purp a:hover {
    transition: all 0.5s ease !important;
    transform: translateX(20px) !important;
}


/* White Text 2 */
#white-2{
    background-color: white;
    color: var(--first-color);
    /* height: 20vw; */
}
#white-2 .top{
    padding-top: 5vw;
    padding-left: 15vw;
    font-size: 1.1vw;
    text-transform: uppercase;
}
#white-2 .down{
    padding: 5vw 0;
    padding-left: 15vw;
    font-size: 3rem;
    width: 50vw;
    font-weight: 500;
}


/* @media screen and (max-width: 1360px) {
    #conocenos #white-text p {
        padding-top: 20vw;
    }
} */
@media screen and (max-width: 1024px) {
    /* Conocenmos Text */
    #con-text{
        height: auto;
    }
    #con-text p {
        padding-top: 7vw;
        width: 70%;
        left: 15vw;
        font-size: 2rem;
    }
    #con-text img {
        width: 100vw;
        padding-top: 80vw;
    }
    
    /* White Text */
    #conocenos #white-text {
        height: auto;
    }
    #conocenos #white-text p {
        width: 80%;
        font-size: 1.6rem;
        padding-top: 10vw;
        padding-left: 10vw;
        margin-bottom: 5rem;
    }
    #conocenos #white-text a{  
        padding-left: 10vw;
        font-size: 1.5rem;
    }

    /* Purple Text */
    #con-purp .top {
        padding-left: 10vw;
        padding-top: 15vw;
        font-size: 1.5rem;
    }
    #con-purp p {
        font-size: 4rem;
        width: 80vw;
        padding-left: 10vw;
        padding-top: 35vw;
    }
    #con-purp a { 
        padding-left: 10vw;
        padding-top: 30vw;
        font-size: 1.5rem;
    }

    /* White Text 2 */
    #white-2{
        height: 40vh;   
    }
    #white-2 .top{
        padding-top: 10vw;
        font-size: 1.5rem;
        padding-left: 10vw;
    }
    #white-2 .down{
        padding-left: 10vw;
        font-size: 3.5rem;
        width: 70vw;
    }
}



@media screen and (max-width: 768px) {
    
    /* Conocenmos Text */
    
    #con-text p {
        position: relative;
        padding: 3rem 0;
        margin: 0;
        width: 70%;
        /* left: 20vw; */
        font-size: 1.1rem;
    }
    #con-text img {
        width: 100vw;
        height: auto;
        padding-top: 0;
    }


    /* White Text */
    
    #conocenos #white-text p {
        width: 70%;
        font-size: 1.1rem;
        padding-top: 10vw;
        padding-left: 15vw;
        margin-bottom: 5rem;

    }
    #conocenos #white-text a{  
        padding-left: 15vw;
        font-size: 1.2rem;
    }

    
    /* Purple Text */
    #con-purp {
        height: 70vh;
    }

    #con-purp .top {
        padding-left: 10vw;
        padding-top: 15vw;
        font-size: 1.2rem;
    }
    #con-purp p {
        font-size: 2.5rem;
        width:auto;
        padding-left: 10vw;
        padding-top: 35vw;
    }
    #con-purp a { 
        padding-left: 10vw;
        padding-top: 20vw;
        font-size: 1.2rem;
    }


    /* White Text 2 */
    #white-2{
        height: 40vh;   
    }
    #white-2 .top{
        padding-top: 15vw;
        font-size: 1.2rem;
        padding-left: 10vw;
    }
    #white-2 .down{
        padding-left: 10vw;
        font-size: 2.2rem;
        width: auto;
    }
}




@media screen and (max-width: 640px) {
    #con-purp {
        height: 100vh;
    }

    #con-purp a {
        padding-top: 30vw;
    }

    #con-purp p {
        font-size: 2rem;
        width: 80%;
    }
    
    #white-2 {
        height: 50vh;
    }
}