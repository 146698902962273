.contact-form {
    padding: 5rem;
    display: flex;
}

.contact-form .title {
    text-transform: uppercase;
    font-weight: 400;
    color: var(--fourth-color);
    width: 45%;
    padding-left: 4vw;
}

.contact-form form{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 55%;
}

.contact-form form input, 
.contact-form form button {
    padding: 0.5rem 0rem;
    font-weight: 500;
}

.contact-form form input,
.contact-form form label {
    width: 100%;
    font-size: 1rem;
    border: none;
    margin-bottom: 0.5rem;
}

.contact-form form input {
    margin-bottom: 2.5rem;
    border-bottom: 1px solid gray;
}

.contact-form form input:hover {
   cursor: text;
}

.contact-form form .bottom-form {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.contact-form form .bottom-form a {
    transition: all 0.5s ease;
}

.contact-form form .bottom-form a:hover {
    color: var(--first-color);
    transform: translateX(5px);
    text-decoration: underline;
}

.contact-form form button {
    cursor: pointer;
    margin-top: 1rem;
    font-size: 1.25rem;
    border: none;
    background: none;
    transition: all 0.5s ease;
}

.contact-form form button:hover {
    color: var(--first-color);
    transform: translateX(15px);
}



@media screen and (max-width: 768px) {
    .contact-form {
        flex-direction: column;
    }
    .contact-form form {
        width: 100%;
    }
}


@media screen and (max-width: 640px) {
    .contact-form {
        padding: 3rem;
    }
    .contact-form .title {
        text-transform: uppercase;
        font-weight: 400;
        font-size: 1.5rem;
        margin-bottom: 2rem;
        color: var(--fourth-color);
        width: auto;
    }
}