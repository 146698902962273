.testimonial-card {
    width: 16rem;
    height: 26rem;
    background: var(--fourth-color);
    color: #fff;
    padding: 2.75rem;
    align-self: center;
    text-align: left;
}

.testimonial-card img {
    object-fit: contain;
    margin-left: 1rem;
    width: 8vw;
    height: 8vw;
    border-radius: 50%;
}

.testimonial-card span {
    color: var(--second-color);
    margin: 0 1rem;
}

.testimonial-card h2 {
    color: var(--first-color);
}


.testimonial-card .description {
    text-align: justify;
}

@media screen and (max-width: 1024px) {
    .testimonial-card {
        width: 15rem;
    }
}